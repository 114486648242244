export const PROJECT_ID = 'onlyone-pro-preprod';
export const CLIENT_ID = '695010368192-tr9dq4v031ug77f8cajj7uc9v053uphh.apps.googleusercontent.com';

export const BASE_URL = 'https://api-preprod.onlyonecard.pro';
export const REDIRECT_URL = 'https://backend-preprod.onlyonecard.pro';
export const API_KEY = 'AIzaSyA0ZLTh8DGP4Rpy6th6koDb87uz5Oizg38';
export const BACKEND_API_BASE_URL = 'https://jarvis-preprod.onlyonecard.pro';
export const FRIDAY_API_BASE_URL = 'https://friday-preprod.onlyonecard.pro';
export const CEREBRO_API_BASE_URL = 'https://cerebro-preprod.onlyonecard.pro';
export const SMAUG_API_BASE_URL = 'https://smaug-preprod.onlyonecard.pro';
export const SIDEKICK_API_BASE_URL = 'https://sidekick-preprod.onlyonecard.pro';
export const NOTIF_CENTER_API_BASE_URL = 'https://notif-center-preprod.onlyonecard.pro';
export const SAURON_API_BASE_URL = 'https://sauron-preprod.onlyonecard.pro';
export const SCROOGE_API_BASE_URL = 'https://scrooge-preprod.onlyonecard.pro';

export const ONLYONE_WEB_APP_BASE_URL = 'https://app-preprod.onlyonecard.pro';
